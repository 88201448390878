define("discourse/plugins/discourse-antivirus/discourse/templates/components/reviewable-upload", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.reviewable.topic}}
    <div class="flagged-post-header">
      <ReviewableTopicLink @reviewable={{this.reviewable}} @tagName="" />
    </div>
  {{/if}}
  
  <div class="reviewable-upload">
    <div class="reviewable-upload-contents">
      <div class="post-contents-wrapper">
        {{#if this.reviewable.target_created_by}}
          <ReviewableCreatedBy
            @user={{this.reviewable.target_created_by}}
            @tagName=""
          />
        {{/if}}
  
        <div class="post-contents">
          <div class="post-body disable-links-to-flagged-upload">
            <CookText @rawText={{this.reviewable.payload.post_raw}} />
          </div>
        </div>
      </div>
  
      <ReviewableField
        @classes="reviewable-upload-details scan-result"
        @name={{i18n "review.file.scan_result"}}
        @value={{this.reviewable.payload.scan_message}}
      />
  
      <ReviewableField
        @classes="reviewable-upload-details filename"
        @name={{i18n "review.file.filename"}}
        @value={{this.reviewable.payload.original_filename}}
      />
  
      <ReviewableField
        @classes="reviewable-upload-details uploaded-by"
        @name={{i18n "review.file.uploaded_by"}}
        @value={{this.reviewable.payload.uploaded_by}}
      />
    </div>
  
    {{yield}}
  </div>
  */
  {
    "id": "7ofkcQhK",
    "block": "[[[41,[30,0,[\"reviewable\",\"topic\"]],[[[1,\"  \"],[10,0],[14,0,\"flagged-post-header\"],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@reviewable\",\"@tagName\"],[[30,0,[\"reviewable\"]],\"\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[10,0],[14,0,\"reviewable-upload\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"reviewable-upload-contents\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"post-contents-wrapper\"],[12],[1,\"\\n\"],[41,[30,0,[\"reviewable\",\"target_created_by\"]],[[[1,\"        \"],[8,[39,3],null,[[\"@user\",\"@tagName\"],[[30,0,[\"reviewable\",\"target_created_by\"]],\"\"]],null],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[10,0],[14,0,\"post-contents\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"post-body disable-links-to-flagged-upload\"],[12],[1,\"\\n          \"],[8,[39,4],null,[[\"@rawText\"],[[30,0,[\"reviewable\",\"payload\",\"post_raw\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[8,[39,5],null,[[\"@classes\",\"@name\",\"@value\"],[\"reviewable-upload-details scan-result\",[28,[37,6],[\"review.file.scan_result\"],null],[30,0,[\"reviewable\",\"payload\",\"scan_message\"]]]],null],[1,\"\\n\\n    \"],[8,[39,5],null,[[\"@classes\",\"@name\",\"@value\"],[\"reviewable-upload-details filename\",[28,[37,6],[\"review.file.filename\"],null],[30,0,[\"reviewable\",\"payload\",\"original_filename\"]]]],null],[1,\"\\n\\n    \"],[8,[39,5],null,[[\"@classes\",\"@name\",\"@value\"],[\"reviewable-upload-details uploaded-by\",[28,[37,6],[\"review.file.uploaded_by\"],null],[30,0,[\"reviewable\",\"payload\",\"uploaded_by\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[18,1,null],[1,\"\\n\"],[13]],[\"&default\"],false,[\"if\",\"div\",\"reviewable-topic-link\",\"reviewable-created-by\",\"cook-text\",\"reviewable-field\",\"i18n\",\"yield\"]]",
    "moduleName": "discourse/plugins/discourse-antivirus/discourse/templates/components/reviewable-upload.hbs",
    "isStrictMode": false
  });
});